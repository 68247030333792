"use client"

import React, { useCallback, useEffect } from "react"
import { HomePageDigitalDataLayerProps } from "@/src/types/pages"
import { useCookies } from "react-cookie"
import { getLocalWarehouseFromCookies } from "@/src/utils/cookieUtils"
import wcsUtils from "src/utils/WCS"
const HomePageDigitalDataLayer: React.FC<HomePageDigitalDataLayerProps> = ({ site, lang }) => {
  const sessionStatus = wcsUtils.isWcsSessionEstablished() ? "Authenticated" : "Unauthenticated"
  const [cookies] = useCookies()
  const setHomePageDigitalDataLayer = useCallback((): void => {
    const [siteLang, siteCountry] = lang.split("-")
    const siteStore = site.slice(-2).toLowerCase()
    const siteReleaseEnv =
      process?.env?.NEXT_PUBLIC_CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION &&
      process?.env?.NEXT_PUBLIC_CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION.toLowerCase() === "prod"
        ? "prod"
        : "nonprod"
    const { deliveryZip, localWH } = getLocalWarehouseFromCookies(cookies)

    if (window) {
      window.digitalData = {
        ...window.digitalData,
        authStatus: sessionStatus,
        deliveryZip,
        localWH,
        siteCountry,
        siteLang: `${siteLang}-${siteCountry?.toUpperCase()}`,
        siteReleaseEnv,
        siteStore,
        pageCMS: "ContentStack",
        pageName: "Home",
        pagePlatform: "react",
        pageType: "Home",
      }
    }
  }, [site, lang, sessionStatus, cookies.invCheckPostalCode, cookies.STORELOCATION])

  useEffect(() => {
    setHomePageDigitalDataLayer()
  }, [setHomePageDigitalDataLayer])

  return null
}

export default HomePageDigitalDataLayer
