const DEFAULT_DELIVERY_ZIP = "na"
const DEFAULT_STORE_LOCATION = { storeLocation: { city: "na" } }

export const getLocalWarehouseFromCookies = (cookies: any) => {
  const {
    invCheckPostalCode: deliveryZip = DEFAULT_DELIVERY_ZIP,
    STORELOCATION: storeLocation = DEFAULT_STORE_LOCATION,
  } = cookies

  return { deliveryZip, localWH: storeLocation.storeLocation?.city }
}
