const WCS_TIMEOUT = 60 * 60 * 1000 // 60 minutes

/**
 * This file contains the utility functions for WCS.
 * Most of these functions were copied from the production site using the Chrome DevTools and modified slightly
 * to be somewhat TypeScript compatible.
 */
const wcsUtils = {
  getCookie: function getCookie(name?: string) {
    if (process) return

    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop()?.split(";").shift()
  },

  isWcsSessionExpired: function isWcsSessionExpired() {
    try {
      const kmsi = wcsUtils.getCookie("kmsi")
      if (typeof kmsi != "undefined" && kmsi != null && kmsi.indexOf("true") >= 0) {
        const hashedUserId = wcsUtils.getCookie("hashedUserId")

        if (!hashedUserId) {
          return true
        }

        // the content of the ksmi cookie looks like this: `true_1715540307354
        const [ksmiBoolean, ksmiTimestamp] = kmsi.split("_")
        if (ksmiBoolean && ksmiTimestamp) {
          const timestamp = parseInt(ksmiTimestamp)
          return Date.now() - timestamp > WCS_TIMEOUT
        }
      }

      return false
    } catch (e) {
      return false
    }
  },

  isKmsiSession: function isKmsiSession() {
    try {
      const kmsi = wcsUtils.getCookie("kmsi")
      if (typeof kmsi === "undefined" || kmsi === null || kmsi === "false") {
        return false
      }
    } catch (e) {
      console.log(e)
    }

    return true
  },
  /**
   * This function is used to keep the user sign in, if the user opted for it
   */
  kmsi: function kmsi() {
    let ksmiApiCallCount = 0
    try {
      if (wcsUtils.isKmsiSession() && wcsUtils.isWcsSessionExpired()) {
        const xhr = new XMLHttpRequest()
        xhr.open("GET", "/kmsi", false)
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              try {
                const t = JSON.parse(xhr.responseText)
                if ("true" === t.kmsi) {
                  ksmiApiCallCount = 0
                } else if (ksmiApiCallCount < 3) {
                  ksmiApiCallCount++
                  wcsUtils.kmsi()
                }
              } catch (e) {
                console.error(e)
              }
            } else {
              console.error("Request failed with status: /kmsi ", xhr.status)

              if (wcsUtils.isWcsSessionExpired()) {
                window.location.href = "/LogonForm?prompt=false&fromPage=/" // Redirect if conditions are met
              }
            }
          }
        }

        xhr.send()
      }
    } catch (error) {
      console.log(error)
    }
  },
  isWcsSessionEstablished: function isWcsSessionEstablished() {
    try {
      const wcsSession = wcsUtils.getCookie("hashedUserId")
      return !!wcsSession
    } catch (e) {
      console.log(e)
    }

    return false
  },
}

export default wcsUtils
