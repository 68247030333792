"use client"

import { useEffect } from "react"

type LegacyProps = {
  pageType: "Home2" | "Product2"
}

export const Legacy = (props: LegacyProps) => {
  const { pageType } = props

  useEffect(() => {
    if (window) {
      // value used by monitoring software MPulse to distinguish between
      // Legacy home page and Browse Modernization
      window.pageType = pageType

      console.log(`pageType: ${window.pageType}`)
    }

    return () => {
      delete window.pageType
    }
  }, [pageType])
  return null
}
